import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
          <path d="M2183 2363 c-13 -2 -23 -9 -23 -14 0 -5 -8 -9 -17 -9 -40 -1 -105
-82 -122 -152 -18 -78 2 -141 67 -205 61 -61 86 -70 44 -15 -28 34 -59 107
-54 125 1 4 5 24 8 45 8 48 51 107 94 127 48 23 129 30 174 15 21 -6 53 -27
72 -46 19 -19 34 -31 34 -26 0 17 -68 97 -99 117 -17 11 -31 23 -31 27 0 4 -6
5 -14 2 -8 -3 -17 -1 -21 5 -6 11 -67 13 -112 4z m110 -30 c-7 -2 -19 -2 -25
0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m27 -13 c0 -5 -5 -10 -11 -10 -5 0 -7 5
-4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-160 -17 c0 -10 -4 -14 -8 -8 -4 6 -11
13 -16 18 -5 4 -2 7 7 7 10 0 17 -8 17 -17z m100 13 c0 -3 -4 -8 -10 -11 -5
-3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-152 -27 c-10 -5 -18 -17
-18 -26 0 -13 5 -12 27 8 l27 24 -19 -30 c-10 -16 -24 -40 -31 -53 -7 -15 -14
-19 -19 -12 -4 6 -1 18 7 27 10 12 10 17 1 20 -22 8 6 53 32 53 6 -1 2 -5 -7
-11z m-57 -82 l-23 -22 17 28 c10 15 20 25 23 22 3 -3 -5 -16 -17 -28z m11
-72 c3 -14 1 -25 -3 -25 -5 0 -9 11 -9 25 0 14 2 25 4 25 2 0 6 -11 8 -25z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
